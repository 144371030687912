import React from 'react';
// import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
import './date.css';
import { Text } from '@dylanlewis/shared';

export const DateSelector = props => {
  const valid = current => {
    return current.day() !== 0 && current.day() !== 1;
  };
  return (
    <div className={`${props.col ? props.col : 'col-12'}`}>
      <div className="form-group">
        <Text mb={[0]} fontSize={'body'} fontWeight="500" fontFamily={'sans'}>
          {props.label}
        </Text>
        <Datetime
          name={props.name}
          onChange={thing => props.handleDateChange(thing._d)}
          value={props.dateTime}
          timeFormat={false}
          closeOnSelect={true}
          isValidDate={valid}
        />
        {props.error && (
          <Text color="red" size="error">
            <i>{props.error}</i>
          </Text>
        )}
      </div>
    </div>
  );
};
